import React, { ChangeEvent, forwardRef, MouseEvent, ReactNode, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { ClearButton, Container, Error, Icon, InputField, Label } from './Input.styles';

interface Props extends Partial<JSX.IntrinsicElements['input']> {
  label?: ReactNode;
  error?: string;
  iconLeft?: React.ReactNode;
  clearable?: boolean;
}

export { InputField };
export const Input = styled(
  forwardRef<HTMLInputElement, Props>(({ label, error, iconLeft, clearable, className, ...inputProps }: Props, ref) => {
    const inputRef = useRef<HTMLInputElement>();

    const onClear = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      if (inputRef.current && inputProps.onChange) {
        const changeEvent: ChangeEvent<HTMLInputElement> = {
          ...e,
          target: inputRef.current,
          currentTarget: inputRef.current,
        };
        changeEvent.target.value = '';
        inputProps.onChange(changeEvent);
      }
    };

    // If a ref is passed in, we need to make sure both that one and
    // our internal "inputRef" get set to the element.
    const refCallback = useCallback(
      (element: HTMLInputElement) => {
        inputRef.current = element;
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      },
      [inputRef, ref],
    );

    return (
      <Container className={className}>
        <label>
          {label && <Label children={label} $required={inputProps.required} />}
          <InputField $error={!!error} $disabled={inputProps.disabled}>
            {iconLeft && <Icon>{iconLeft}</Icon>}
            <input {...inputProps} ref={refCallback} />
            {clearable && inputProps.value && <ClearButton onClick={onClear} />}
          </InputField>
        </label>
        {error && <Error>{error}</Error>}
      </Container>
    );
  }),
)``;
